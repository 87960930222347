import React, { useContext } from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

// COMPONENTS (BY ORDER)
import Header from './components/Header/Header'
import Tab from './components/Tab/Tab'

// CONTEXTS
import { KeycloackContext } from './contexts/KeycloackContext'

// PAGES (BY ORDER)
import Dashboard from './pages/Dashboard/Dashboard'
import Device from './pages/Device/Device'
import Stock from './pages/Stock/Stock'
import DeviceDetail from './pages/DeviceDetail/DeviceDetail'

// STYLES
import './app.scss'

const App = () => {
    const { keycloackValue, authenticated } = useContext(KeycloackContext)

    return (
        (keycloackValue && authenticated) &&
        <div className='app-root'>
            <Router>
                <Header/>
                <Tab/>

                <Redirect from='/' to='/dashboard'/>
                <Switch>
                    <Route exact path='/dashboard'>
                        <Dashboard/>
                    </Route>
                    <Route exact path='/device'>
                        <Device/>
                    </Route>
                    <Route exact path='/stock'>
                        <Stock/>
                    </Route>
                    <Route path='/device/detail/id=:deviceId'>
                        <DeviceDetail/>
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default App