import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import CardsGrid from './CardsGrid/CardsGrid'
import TotalNewDevicesChart from './TotalNewDevicesChart/TotalNewDevicesChart'
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup'

// CONTEXTS
import { KeycloackContext } from '../../contexts/KeycloackContext'

// DATE FNS
import { format } from 'date-fns'

// REACT LOADING
import ReactLoading from 'react-loading'

// STYLES
import './dashboard.scss'

// UTILS
import { 
    fetchWulingAPIGetState, 
    formatWulingAPIGetState 
} from '../../utils/fetchWulingAPIGetState'

const Dashboard = () => {
    // const TAG = 'Dashboard'
    
    const { keycloackValue } = useContext(KeycloackContext)
    
    const initialSelectedSalesPoint = 'Sales Point'
    const [ salesPointList, setSalesPointList ] = useState(null)
    const [ selectedSalesPoint, setSelectedSalesPoint ] = useState(initialSelectedSalesPoint)

    const [ cardValueList, setCardValueList ] = useState(null)

    const [ labelList, setLabelList ] = useState(null)
    const [ totalNewDeviceList, setTotalNewDeviceList ] = useState(null)

    const [ isLoading, setIsLoading ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState(null)

    // RETURN LAST 30 DAYS LIST IN STRING (DD-MMM-YYYY)
    const last30DaysList = [...Array(30)].map((item, index) => {
        const today = new Date()
        const newDay = new Date(today.setDate(today.getDate() - 29 + index))
        return format(newDay, 'dd-MMM-yyyy')
    })

    const getFormattedData = async () => {
        setIsLoading(true)

        const data = await formatWulingAPIGetState(keycloackValue['token'])
        // console.log(`${TAG}, data: `, data)

        if(data['status'] === 403) {
			const message = 'Your email address is not registered as a valid role. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setSalesPointList(null)
            setSelectedSalesPoint(null)
            setCardValueList(null)
		}
		else if(data['status'] === 500) {
			const message = 'Internal server error. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setSalesPointList(null)
            setSelectedSalesPoint(null)
            setCardValueList(null)
		}
		else {
            setSalesPointList(provideDataForDropdown(data))
            if(selectedSalesPoint === initialSelectedSalesPoint) {
                setSelectedSalesPoint(provideDataForDropdown(data)[0])
                setCardValueList(getTotalData(data))
            }
            else {
                const filteredData = data.filter(item => item['sales_point'] === selectedSalesPoint)
                setCardValueList(getTotalData(filteredData))
            }
        }        

        setIsLoading(false)
    }

    // RETURN LIST OF UNIQUE SALES POINTS
    const provideDataForDropdown = (inputData) => {
		const allGroupTitleList = inputData.map(item => item['sales_point'])
		// RETURN ALL UNIQUE ITEM INSIDE ARRAY
		const output = ['Sales Point'].concat([... new Set(allGroupTitleList)])
		return output
	}

    // RETURN TOTAL, ONLINE, AND OFFLINE DEVICES FOR CARD COMPONENTS
    const getTotalData = (inputData) => {
        let [ totalDevices, onlineDevices, offlineDevices ] = [ 0, 0, 0 ]
        inputData.forEach(item => {
            totalDevices = totalDevices + item['total_device']
            onlineDevices = onlineDevices + item['online']
            offlineDevices = offlineDevices + item['offline']
        })
        return [ totalDevices, onlineDevices, offlineDevices ]
    }

    const getStateData = async () => {
        setIsLoading(true)

        const data = await fetchWulingAPIGetState(keycloackValue['token'])
        // console.log(`${TAG}, data: `, data)

        if(data['status'] === 403) {
			const message = 'Your email address is not registered as a valid role. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setLabelList(null)
            setTotalNewDeviceList(null)
		}
		else if(data['status'] === 500) {
			const message = 'Internal server error. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setLabelList(null)
            setTotalNewDeviceList(null)
		}
		else {
            setLabelList(last30DaysList)
    
            if(selectedSalesPoint === initialSelectedSalesPoint) {
                setTotalNewDeviceList(getTotalNewDevicesData(data))
            }
            else {
                const filteredData = data.filter(item => item['group_title'] === selectedSalesPoint)
                setTotalNewDeviceList(getTotalNewDevicesData(filteredData))
            }
        }

        setIsLoading(false)
    }

    // RETURN TOTAL NEW DEVICES FOR LAST 30 DAYS
    const getTotalNewDevicesData = (inputData) => {
        let totalNewDevicesData = Array(30).fill(0)
        for(let i = 0; i < inputData.length; i++) {
            for(let j = 0; j < totalNewDevicesData.length; j++) {
                const creationDate = format(new Date(inputData[i]['creation_date']), 'dd-MMM-yyyy')
                // INCREMENT TOTAL NEW DEVICE IF BOTH DATA ARE SAME
                if(creationDate === last30DaysList[j]) {
                    totalNewDevicesData[j]++
                }
            }
        }
        return totalNewDevicesData
    }

    useEffect(() => {
        getFormattedData()
        getStateData()
    }, [selectedSalesPoint]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectComponent =
    <select
        className='dashboard-select'
		value={selectedSalesPoint} 
		onChange={(event) => setSelectedSalesPoint(event.target.value)}
    >
        {salesPointList && salesPointList.map((item, index) => (
			<option 
				key={index} 
				value={item}
				className='dashboard-option'
			>
				{item}
			</option>
		))}
    </select>

    return (
        <div className='dashboard-root'>
            {
                isLoading ? 
				<div className='dashboard-card dashboard-card-loading'>
					<ReactLoading 
						type='spin' 
						color='tomato' 
						height='50px' 
						width='50px'
					/>
				</div> :
                <>
                    {
						errorMessage ?
						<ErrorPopup message={errorMessage}/> :
                        <>
                            {/* SELECT COMPONENT */}
                            <div className='dashboard-select-container'>
                                {selectComponent}
                            </div>

                            {/* DIVIDER */}
                            <div className='dashboard-divider'></div>

                            {/* TOTAL DEVICES, ONLINE, AND OFFLINE CARDS */}
                            <CardsGrid
                                cardValueList={cardValueList}
                            />

                            {/* CHART */}
                            <TotalNewDevicesChart
                                labelList={labelList}
                                totalNewDeviceList={totalNewDeviceList}
                            />
                        </>
					}
                </>
            }
        </div>
    )
}

export default Dashboard