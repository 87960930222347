import React, { useContext } from 'react'

// CONTEXTS
import { KeycloackContext } from '../../contexts/KeycloackContext'

// STYLES
import './logoutPopup.scss'

const LogoutPopup = () => {
    const { keycloackValue, logout } = useContext(KeycloackContext)

    return (
        <div className='logout-popup-root'>
            <p className='logout-popup-username'>
                {keycloackValue['idTokenParsed']['name']}
            </p>
            <p className='logout-popup-email'>
                {keycloackValue['idTokenParsed']['email']}
            </p>
            <div className='logout-popup-divider'></div>
            <div 
                className='logout-popup-logout-button'
                onClick={() => logout()}
            >
                Log Out
            </div>
        </div>
    )
}

export default LogoutPopup