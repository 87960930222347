import React from 'react'

// REACT APEX CHART
import ReactApexChart from 'react-apexcharts'

// STYLES
import './totalNewDevicesChart.scss'

const TotalNewDevicesChart = (props) => {
    const { labelList, totalNewDeviceList } = props

    const chartOptions = {
        chart: { 
            height: '90%', 
            type: 'bar', 
            toolbar: 
            { 
                show: false 
            } 
        },
        plotOptions: {
            bar: { 
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 5,
            } 
        },
        colors: [ '#FFA800' ],
        fill: {
            opacity: 1,
        },
        labels: labelList,
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 5,
            hover: {
                size: 9
            }
        },
        grid: {
            show: true,
            borderColor: '#EFEFEF',
            strokeDashArray: 5,
            position: 'back',
            xaxis: {
                lines: {
                    show: false,
                },
            },   
            yaxis: {
                lines: {
                    show: true,
                },
            },  
            row: {
                colors: undefined,
                opacity: 0.5
            },  
            column: {
                colors: undefined,
                opacity: 0.5
            },  
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10
            },  
        },
    }

    const chartSeries = [
        {
            name: 'Total New Devices',
            type: 'column',
            data: totalNewDeviceList,
        },
    ]

    return (
        <div className='total-new-devices-chart-chart-container'>
            {/* TITLE */}
            <div className='total-new-devices-chart-chart-title'>
                Total New Devices
            </div>

            {/* CHART */}
            {
                totalNewDeviceList &&
                <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type='bar'
                    height={'90%'}
                />
            }
        </div>
    )
}

export default TotalNewDevicesChart