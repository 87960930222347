import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import MapView from './MapView/MapView'
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup'

// CONTEXTS
import { KeycloackContext } from '../../contexts/KeycloackContext'

// DATE FNS
import { format } from 'date-fns'

// IMAGES
import IconBack from '../../images/icons/back.svg'

// MATERIAL UI
import Grid from '@material-ui/core/Grid'

// REACT LOADING
import ReactLoading from 'react-loading'

// STYLES
import './deviceDetail.scss'
import useStyles from './deviceDetailUseStyles'

// UTILS
import { fetchWulingAPIGetState } from '../../utils/fetchWulingAPIGetState'

const DeviceDetail = () => {
    // const TAG = 'Device Detail'

    const { keycloackValue } = useContext(KeycloackContext)

    const classes = useStyles()
    
    const history = useHistory()

    const locationUrl = useLocation()
    const deviceId = locationUrl['pathname'].split('/')[3].replace('id=', '')

    const [ bottomDeviceData, setBottomDeviceData ] = useState(null)
    const [ topDeviceData, setTopDeviceData ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState(null)

    const topTitleList = [ 'Vehicle Label', 'Sales Point' ]

    const bottomTitleList = [ 
        'Blocked', 'Status', 'Device ID', 'Device SIM',
        'Movement', 'Registered', 'Speed', 'Heading', 
        'Battery Level', 'GSM Network', 'GSM Signal', 'GSM Time',
        'GPS Signal', 'GPS Time', 'Latitude', 'Longitude',
    ]

    const getStateData = async () => {
        setIsLoading(true)
        const data = await fetchWulingAPIGetState(keycloackValue['token'])
		// console.log(`${TAG}, data: `, data)
        const newData = data.filter(item => item['tracker_id'] === parseInt(deviceId))[0]
        // console.log(`${TAG}, newData: `, newData)
        if(newData != undefined) {
            setTopDeviceData( [ newData['label'], newData['group_title'] ])
            setBottomDeviceData(pushObjectToBottomList(newData))
            setErrorMessage(null)
        }
        else {
            setErrorMessage(`Sorry, there is no device with id equals ${deviceId}`)
        }
        setIsLoading(false)
    }

    const pushObjectToBottomList = (inputObject) => {
        let output = []
        output.push(inputObject['blocked'] ? 'True' : 'False')
        output.push(inputObject['connection_status'].replace(/_/g, ' '))
        output.push(inputObject['src_device_id'])
        output.push(inputObject['src_phone'])
        output.push(inputObject['movement_status'])
        output.push(format(new Date(inputObject['creation_date']), 'dd MMM yyyy, HH:mm aa'))
        output.push(inputObject['gps_speed'])
        output.push(inputObject['gps_heading'])
        output.push(`${inputObject['battery_level']} %`)
        output.push(inputObject['gsm_network_name'])
        output.push(inputObject['gsm_signal_level'])
        output.push(format(new Date(inputObject['gsm_updated']), 'dd MMM yyyy, HH:mm aa'))
        output.push(inputObject['gps_signal_level'])
        output.push(format(new Date(inputObject['gps_updated']), 'dd MMM yyyy, HH:mm aa'))
        output.push(inputObject['gps_location_lat'])
        output.push(inputObject['gps_location_lng'])
        return output
    }

    useEffect(() => {
        getStateData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const topGridComponent = 
    topDeviceData && topDeviceData.map((item, index) => (
        <Grid 
            key={index}
            item 
            xs={6}
            className={classes['gridItem']}
        >
            <div className={classes['itemKey']}>
                {topTitleList[index]}
            </div>
            <div className={`${classes['itemValue']} ${classes['itemValueCapitalize']}`}>
                {topDeviceData && item}
            </div>
        </Grid>
    ))

    const bottomGridComponent = 
    bottomDeviceData && bottomDeviceData.map((item, index) => (
        <Grid 
            key={index}
            item 
            xs={3}
            className={classes['gridItem']}
        >
            <div className={classes['itemKey']}>
                {bottomTitleList[index]}
            </div>
            <div className={`${classes['itemValue']} ${classes['itemValueCapitalize']}`}>
                {bottomDeviceData && item}
            </div>
        </Grid>
    ))

    const leftGridComponent =
    <Grid container>
        {/* TOP DEVICE DATA */}
        {topGridComponent}

        {/* DIVISOR LINE */}
        <div className={classes['divisorLine']}></div>

        {/* BOTTOM DEVICE DATA */}
        {bottomGridComponent}
    </Grid>

    const gridParentComponent =
    <Grid 
        container
        className={classes['gridContainer']}
    >
        {/* LEFT GRID DATA */}
        <Grid 
            item 
            xs={9}
        >
            {leftGridComponent}
        </Grid>

        {/* MAP VIEW COMPONENT */}
        <Grid 
            item 
            xs={3}
        >
            <div className={classes['itemKey']}>
                Maps
            </div>
            <MapView
                className={'device-detail-map'}
                location={bottomDeviceData && [bottomDeviceData[14], bottomDeviceData[15]]}
                height={480}
                width={'100%'}
            />
        </Grid>
    </Grid>

    return (
        <div className='device-detail-root'>
            {
                isLoading ? 
                <div className='device-detail-card device-detail-card-loading'>
					<ReactLoading 
						type='spin' 
						color='tomato' 
						height='50px' 
						width='50px'
					/>
				</div> :
                <div className='device-detail-card'>
                    {
						errorMessage && 
						<ErrorPopup message={errorMessage}/>
					}
                    {/* BACK BUTTON AND TITLE */}
                    <div className='device-detail-back-and-title'>
                        <div className='device-detail-title'>Details</div>
                        <img 
                            src={IconBack} 
                            alt='' 
                            className='device-detail-back'
                            onClick={() => history.goBack()}
                        />
                    </div>

                    {/* DATA */}
                    { (topDeviceData && bottomDeviceData) && gridParentComponent }
                </div>
            }
        </div>
    )
}

export default DeviceDetail