import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// STYLES
import './tab.scss'

const Tab = () => {
    const tabList = [ 'Dashboard', 'Device', 'Stock' ]

    const locationUrl = useLocation()
    const pageName = locationUrl['pathname'].split('/')[1]

    const history = useHistory()

    const textClassName = (inputString) =>{
        return pageName === inputString.toLowerCase() ? 'tab-button-selected' : 'tab-button-unselected'
    } 

    const underlineClassName = (inputString) =>{
        return pageName === inputString.toLowerCase() && 'tab-underline-selected'
    } 

    return (
        <div className='tab-root'>
            {
                tabList.map((item, index) => (
                    <div 
                        key={index}
                        onClick={() => history.push(`/${item.toLowerCase()}`)}
                    >
                        {/* TEXT */}
                        <div 
                            className={`tab-button ${textClassName(item)}`}
                        >
                            {item}
                        </div>

                        {/* UNDERLINE */}
                        <div 
                            className={`tab-underline ${underlineClassName(item)}`}
                        >
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Tab