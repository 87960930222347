const baseUrl = process.env.REACT_APP_WULING_API_BASE

// const TAG = 'fetchWulingAPIGetState'

const fetchWulingAPIGetState = async (inputToken) => {
    const COMPLETEURL = baseUrl

    const response = await fetch(COMPLETEURL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${inputToken}`
        }
    })
    // console.log(`${TAG}, response: `, response)
    const responseJson = await response.json()
    // console.log(`${TAG}, reponseJson: `, responseJson)
    return responseJson
}

// NOTE:
// Sales Point -> item['group_title']
// Stock -> item['connection_status'] === 'just_registered'
// Online -> item['connection_status'] === 'active' || 'idle'
// Offline -> item['connection_status'] === 'offline' || 'signal_lost'

// formattedOutput = [
//     {
//         'sales_point': ...,
//         'stock': ...,
//         'online': ...,
//         'offline': ...,
//         'min_stock': 10
//     },
//     ...
// ]

const updateStockOnlineAndOfflineCount = (inputRawItem, inputNewItem) => {
    if(inputRawItem['connection_status'] === 'just_registered') {
        inputNewItem['stock']++
    }
    else if(inputRawItem['connection_status'] === 'active' || inputRawItem['connection_status'] === 'idle') {
        inputNewItem['online']++
    }
    else if(inputRawItem['connection_status'] === 'offline' || inputRawItem['connection_status'] === 'signal_lost') {
        inputNewItem['offline']++
    }
    inputNewItem['total_device']++
    return inputNewItem
}

const addNewItemToList = (inputRawItem, inputList) => {
    let newItem = {
        'sales_point': inputRawItem['group_title'],
        'stock': 0,
        'online': 0,
        'offline': 0,
        'min_stock': 10,
        'total_device': 0,
    }
    newItem = updateStockOnlineAndOfflineCount(inputRawItem, newItem)
    inputList.push(newItem)
}

const formatWulingAPIGetState = async (inputToken) => {
    const rawData = await fetchWulingAPIGetState(inputToken)

    if(rawData['status'] === 403 || rawData['status'] === 500) {
        return rawData
    }

    let output = []
    // CHECK FOR EVERY ITEM INSIDE RAW DATA
    for(let i = 0; i < rawData.length; i++) {
        const rawItem = rawData[i]

        // ADD A NEW ITEM IF THE OUTPUT IS EMPTY
        if(output.length === 0) {
            addNewItemToList(rawItem, output)
        }
        // ADD A NEW ITEM IF THE OUTPUT IS NOT EMPTY
        else {
            // SEARCH IF THE 'GROUP TITLE' FROM RAW ITEM IS AVAILABLE IN OUTPUT OR NOT
            let found = 0
            for(let j = 0; j < output.length; j++) {
                let outputItem = output[j]
                if(outputItem['sales_point'] === rawItem['group_title']) {
                    found++

                    // UPDATE THE ITEM IF THE 'GROUP TITLE' FROM RAW ITEM IS AVAILABLE IN OUTPUT
                    outputItem = updateStockOnlineAndOfflineCount(rawItem, outputItem)
                }
            }

            // ADD A NEW ITEM IF THE 'GROUP TITLE' FROM RAW ITEM IS NOT AVAILABLE IN OUTPUT
            if(found === 0) {
                addNewItemToList(rawItem, output)
            }
        }
    }
    return output
}

export { fetchWulingAPIGetState, formatWulingAPIGetState }
