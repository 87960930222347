import React, { useState } from 'react'

// STYLES
import './mapView.scss'

// REACT MAP GL
import ReactMapGL, { Marker } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MapView = (props) => {
    const { className, location, height, width } = props

    const [ viewport, setViewport ] = useState({
        latitude: location[0],
        longitude: location[1],
        height: height,
        width: width,
        zoom: 15,
    })
    
    const MAPBOX_TOKEN = process.env.REACT_APP_WULING_MAPBOX_TOKEN

    return (
        <div className={className}>
            <ReactMapGL
                {...viewport}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                // SOURCE OF STYLES https://docs.mapbox.com/api/maps/styles/
                mapStyle={'mapbox://styles/mapbox/streets-v11'}
                onViewportChange={viewport => {
                    setViewport(viewport)
                }}
            >
                <Marker
                    latitude={location[0]}
                    longitude={location[1]}
                >
                    {/* <div className='border-marker'>
                        <div className='marker'/>
                    </div> */}
                    <div className='mapview-pulsing-marker'/>
                </Marker>
            </ReactMapGL>
        </div>
    )
}

export default MapView