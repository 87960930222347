import React from 'react'

// IMAGES
import BackgroundItemBlue from '../../../images/backgrounds/item_dashboard_blue.svg'
import BackgroundItemGreen from '../../../images/backgrounds/item_dashboard_green.svg'
import BackgroundItemRed from '../../../images/backgrounds/item_dashboard_red.svg'

// MATERIAL UI
import Grid from '@material-ui/core/Grid'

// STYLES
import useStyles from './cardsGridUseStyles'

const CardsGrid = (props) => {
    const { cardValueList } = props

    // total devices, online, and offline
    const cardTitleList = [ 'Total Devices', 'Online', 'Offline' ]
    const cardBackgroundList = [ BackgroundItemBlue, BackgroundItemGreen, BackgroundItemRed ]
    const cardColorCircleList = [ null, '#13A19B', '#B72131' ]

    const classes = useStyles()

    return (
        <Grid 
            container
            className={classes['gridContainer']}
        >
            {cardValueList && cardValueList.map((item, index) => {
                let leftMiddleOrRight
                if(index % 3 === 0) {
                    leftMiddleOrRight = classes['gridItemLeft']
                }
                else if(index % 3 === 1) {
                    leftMiddleOrRight = classes['gridItemMiddle']
                }
                else if(index % 3 === 2) {
                    leftMiddleOrRight = classes['gridItemRight']
                }

                return(
                    <Grid 
                        key={index} 
                        item
                        xs={4}
                    >
                        <div 
                            className={`${classes['gridItem']} ${leftMiddleOrRight}`}
                            style={{ backgroundImage: `url(${cardBackgroundList[index]})` }}
                        >
                            {/* CONTENT */}
                            <div className={classes['gridItemContent']}>
                                <div className={classes['gridItemTitleContainer']}>
                                    {/* TITLE */}
                                    <div className={classes['gridItemTitle']}>
                                        {cardTitleList[index]}
                                    </div>

                                    {/* CIRCLE */}
                                    {
                                        cardColorCircleList[index] &&
                                        <div 
                                            className={classes['gridItemCircle']}
                                            style={{backgroundColor: cardColorCircleList[index]}}
                                        />
                                    }
                                </div>

                                {/* VALUE */}
                                <div className={classes['gridItemValue']}>
                                    {item}
                                </div>
                            </div>
                        </div>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default CardsGrid