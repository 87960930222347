import React from 'react'

// STYLES
import './errorPopup.scss'

const ErrorPopup = (props) => {
    const { message } = props

    return (
        <div className='error-popup-root'>
            {message}
        </div>
    )
}

export default ErrorPopup