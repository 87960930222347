import React, { useState } from 'react'

// COMPONENTS
import LogoutPopup from '../LogoutPopup/LogoutPopup'

// IMAGES
import BackgroundLeaf from '../../images/backgrounds/horizontal-white-leaf.svg'
import LogoWuling from '../../images/logos/wuling.png'
import IconProfile from '../../images/icons/profile.svg'

// STYLES
import './header.scss'

const Header = () => {
    const [ isLogoutPopupShown, setIsLogoutPopupShown ] = useState(false)

    return (
        <div className='header-root'>
            {/* LOGO */}
            <div className='header-logo-container'>
                <img 
                    src={BackgroundLeaf} 
                    alt='' 
                    className='header-logo-background'
                />
                <img 
                    src={LogoWuling} 
                    alt='' 
                    className='header-logo-image'
                />
            </div>

            {/* PAGE TITLE */}
            <p className='header-title'>GPS Stock Management</p>

            {/* PROFILE */}
            <div 
                className='header-profile-container'
                onClick={() => setIsLogoutPopupShown(previousValue => !previousValue)}
            >
                <img src={IconProfile} alt=''/>
            </div>

            {/* LOGOUT POPUP */}
            {isLogoutPopupShown && <LogoutPopup/>}
        </div>
    )
}

export default Header