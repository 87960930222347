import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import Table from '../../components/Table/Table'
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup'

// CONTEXTS
import { KeycloackContext } from '../../contexts/KeycloackContext'

// IMAGE
import IconArrowDown from '../../images/icons/arrow_down_red.svg'
import IconArrowUp from '../../images/icons/arrow_up_red.svg'

// REACT LOADING
import ReactLoading from 'react-loading'

// STYLES
import './stock.scss'

// UTILS
import { formatWulingAPIGetState } from '../../utils/fetchWulingAPIGetState'
import { exportStockDataToExcel } from '../../utils/exportGetStateToExcel'

const Stock = () => {
    // const TAG = 'Stock'

    const { keycloackValue } = useContext(KeycloackContext)

    const [ tableData, setTableData ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState(null)

	const sortCaret = (order, column) => {
		if (!order) return null
		else if (order === 'asc') {
			return (
				<img 
					className='stock-sort-caret' 
					src={IconArrowUp} 
					alt=''
				/>
			)
		} 
		else if (order === 'desc') {
			return (
				<img 
					className='stock-sort-caret' 
					src={IconArrowDown} 
					alt=''
				/>
			)
		} 
		return null
	}

    const columns = [
        {
			dataField: 'no',
			text: '#',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '5%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'sales_point',
			text: 'Sales Point',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '50%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'stock',
			text: 'Stock',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '10%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'online',
			text: 'Online',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '10%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'offline',
			text: 'Offline',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '10%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'min_stock',
			text: 'Min Stock',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '15%' }
			},
			sortCaret: sortCaret,
		},
	]

	const defaultSorted = [{
		dataField: 'no',
		order: 'asc',
	}]

    const addNumberFieldToTable = (inputData) => {
        const output = inputData.map((item, index) => {
            item['no'] = index + 1
            return item
        })
        return output
    }

    const getFormattedData = async () => {
		setIsLoading(true)
        const data = await formatWulingAPIGetState(keycloackValue['token'])
		if(data['status'] === 403) {
			const message = 'Your email address is not registered as a valid role. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setTableData(null)
		}
		else if(data['status'] === 500) {
			const message = 'Internal server error. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setTableData(null)
		}
		else {
			// console.log(`${TAG}, getFormattedData: `, data)
			setTableData(addNumberFieldToTable(data))
			setErrorMessage(null)
		}
		setIsLoading(false)
    }

    useEffect(() => {
        getFormattedData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='stock-root'>
			{
				isLoading ? 
				<div className='stock-card stock-card-loading'>
					<ReactLoading 
						type='spin' 
						color='tomato' 
						height='50px' 
						width='50px'
					/>
				</div> :
				<div className='stock-card'>
					{
						errorMessage && 
						<ErrorPopup message={errorMessage}/>
					}
					{
						tableData && 
						<Table 
							tableData={tableData} 
							columns={columns}
							actionExport={() => exportStockDataToExcel(tableData)}
							keyField={'no'}
							defaultSorted={defaultSorted}
							actionSelect={null}
						/>
					}
            	</div>
			}
        </div>
    )
}

export default Stock