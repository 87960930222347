import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import Table from '../../components/Table/Table'
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup'

// CONTEXTS
import { KeycloackContext } from '../../contexts/KeycloackContext'

// DATE FNS
import { format } from 'date-fns'

// IMAGE
import IconArrowDown from '../../images/icons/arrow_down_red.svg'
import IconArrowUp from '../../images/icons/arrow_up_red.svg'
import IconDetail from '../../images/icons/detail.svg'

// REACT LOADING
import ReactLoading from 'react-loading'

// UTILS
import { fetchWulingAPIGetState } from '../../utils/fetchWulingAPIGetState'
import { exportDeviceDataToExcel } from '../../utils/exportGetStateToExcel'

// STYLES
import './device.scss'

const Device = () => {
    // const TAG = 'Device'

    const { keycloackValue } = useContext(KeycloackContext)

	const history = useHistory()

    const [ tableData, setTableData ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState(null)

	const initialSelectedSalesPoint = 'Sales Point'
	const [ dropdownData, setDropdownData ] = useState(null)
	const [ selectedSalesPoint, setSelectedSalesPoint ] = useState(initialSelectedSalesPoint)

	const statusColumnRenderer = (cell, row, rowIndex, formatExtraData) => {
		return(
			<div className={`device-table-status-column ${cell}`}>
				{cell.replace(/_/g, ' ')}
			</div>
		)
	}

	const dateAddedRenderer = (cell, row, rowIndex, formatExtraData) => {
		return(
			<div>
				{format(new Date(cell), 'dd MMM yyyy')}
			</div>
		)
	}

	const detailColumnRenderer = (cell, row, rowIndex, formatExtraData) => {
		return(
			<div 
				className='device-table-detail-column'
				onClick={() => history.push(`device/detail/id=${cell}`)}
			>
				<img 
					src={IconDetail} 
					alt='' 
					className='device-table-detail-column'
				/>
			</div>
		)
	}

	const sortCaret = (order, column) => {
		if (!order) return null
		else if (order === 'asc') {
			return (
				<img 
					className='device-sort-caret' 
					src={IconArrowUp} 
					alt=''
				/>
			)
		} 
		else if (order === 'desc') {
			return (
				<img 
					className='device-sort-caret' 
					src={IconArrowDown} 
					alt=''
				/>
			)
		} 
		return null
	}

    const columns = [
		{
			dataField: 'tracker_id',
			text: 'ID',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '5%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'label',
			text: 'Name',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '25%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'src_phone',
			text: 'Phone',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '11%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'src_device_id',
			text: 'Device ID',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '12%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'creation_date',
			text: 'Date Added',
			sort: true,
			formatter: dateAddedRenderer,
			headerStyle: (column, colIndex) => {
				return { width: '9%' }
			},
			sortCaret: sortCaret,
		},
        {
			dataField: 'group_title',
			text: 'Sales Point',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '21%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'connection_status',
			text: 'Status',
			sort: true,
			formatter: statusColumnRenderer,
			headerStyle: (column, colIndex) => {
				return { width: '12%' }
			},
			sortCaret: sortCaret,
		},
		{
			dataField: 'tracker_id',
			text: '',
			sort: true,
			formatter: detailColumnRenderer,
			headerStyle: (column, colIndex) => {
				return { width: '4%' }
			},
		},
	]

	const defaultSorted = [{
		dataField: 'tracker_id',
		order: 'asc',
	}]

    const getStateData = async () => {
		setIsLoading(true)
        const data = await fetchWulingAPIGetState(keycloackValue['token'])
		// console.log(`${TAG}, data: `, data)
		if(data['status'] === 403) {
			const message = 'Your email address is not registered as a valid role. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setTableData(null)
		}
		else if(data['status'] === 500) {
			const message = 'Internal server error. Please contact your admin.'
			setErrorMessage(`Error ${data['status']}: ${data['error']}! ${message}`)
			setTableData(null)
		}
		else {
			const newData = data.map(item => {
				item['creation_date'] = new Date(item['creation_date']).getTime()
				return item
			})
			// console.log(`${TAG}, getFormattedData: `, newData)
			setDropdownData(provideDataForDropdown(newData))
			if(selectedSalesPoint === initialSelectedSalesPoint) {
				setSelectedSalesPoint(provideDataForDropdown(newData)[0])
				setTableData(newData)
			}
			else {
				const filteredData = newData.filter(item => item['group_title'] === selectedSalesPoint)
				setTableData(filteredData)
			}
			setErrorMessage(null)
		}
		setIsLoading(false)
    }

	const provideDataForDropdown = (inputData) => {
		const allGroupTitleList = inputData.map(item => item['group_title'])
		// RETURN ALL UNIQUE ITEM INSIDE ARRAY
		const output = ['Sales Point'].concat([... new Set(allGroupTitleList)])
		return output
	}

    useEffect(() => {
        getStateData()
    }, [selectedSalesPoint]) // eslint-disable-line react-hooks/exhaustive-deps

	const selectComponent =
	<select 
		className='device-select'
		value={selectedSalesPoint} 
		onChange={(event) => setSelectedSalesPoint(event.target.value)}
	>
		{dropdownData && dropdownData.map((item, index) => (
			<option 
				key={index} 
				value={item}
				className='device-option'
			>
				{item}
			</option>
		))}
	</select>

    return (
        <div className='device-root'>
			{
				isLoading ? 
				<div className='device-card device-card-loading'>
					<ReactLoading 
						type='spin' 
						color='tomato' 
						height='50px' 
						width='50px'
					/>
				</div> :
				<div className='device-card'>
					{
						errorMessage && 
						<ErrorPopup message={errorMessage}/>
					}
					{
						tableData && 
						<Table 
							tableData={tableData} 
							columns={columns}
							actionExport={() => exportDeviceDataToExcel(tableData)}
							keyField={'tracker_id'}
							defaultSorted={defaultSorted}
							actionSelect={selectComponent}
						/>
					}
            	</div>
			}
        </div>
    )
}

export default Device