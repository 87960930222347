import React, { useState } from 'react'

// BOOTSTRAP
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

// IMAGES
import IconExport from '../../images/icons/export.svg'

// STYLES
import './table.scss'

const Table = (props) => {

    const { 
        tableData, 
        columns, 
        actionExport, 
        keyField, 
        defaultSorted, 
        actionSelect 
    } = props

    let initialStartAndEndPage = `1 - 10`
    if(tableData.length < 10) {
        initialStartAndEndPage = `1 - ${tableData.length}`
    }

    const [ startAndEndPage, setStartAndEndPage ] = useState(initialStartAndEndPage)
    const [ selectedSizePerPage, setSelectedSizePerPage ] = useState(10) 

    const CUSTOMWHITESMOKE = '#F9F3F4'
    const CUSTOMTOMATO = '#FF3C3C'

    const pageButtonRenderer = ({
        page,
        active,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault()
            onPageChange(page)
        }

        const activeStyle = {}

        if (active) {
            activeStyle.backgroundColor = CUSTOMTOMATO
            activeStyle.color = 'white'
        } else {
            activeStyle.backgroundColor = 'white'
            activeStyle.color = 'black'
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = CUSTOMWHITESMOKE
            activeStyle.color = CUSTOMTOMATO
        }

        return (
            <li className='page-item'>
                <div 
                    className='pagination-anchor' 
                    onClick={handleClick} 
                    style={activeStyle}
                >
                    {page}
                </div>
            </li>
        )
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role='presentation'
            className='dropdown-item size-per-page-custom'
            onMouseDown={(e) => {
                e.preventDefault()
                onSizePerPageChange(page)
            }}
        >
            <div
                className='size-per-page-text'
                tabIndex='-1'
                role='menuitem'
                data-page={page}
            >
                {text}
            </div>
        </li>
    )

    const paginationOptions = {
        pageButtonRenderer,
        sizePerPageOptionRenderer,
        sizePerPage: selectedSizePerPage,
        sizePerPageList: [ 10, 20, 50 ],
        totalSize: tableData.length,
        custom: true,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        alwaysShowAllBtns: true,
        onSizePerPageChange: (sizePerPage, page) => {
            updateStartAndEndPage(page, sizePerPage)
        },
        onPageChange: (page, sizePerPage) => {
            updateStartAndEndPage(page, sizePerPage)
        }
    }

    const updateStartAndEndPage = (inputPage, inputSizePerPage) => {
        let endPage = inputSizePerPage * inputPage
        let startPage = endPage - inputSizePerPage + 1
        if(endPage > tableData.length) {
            endPage = tableData.length
        }
        setStartAndEndPage(`${startPage} - ${endPage}`)
        setSelectedSizePerPage(inputSizePerPage)
    }

    const { SearchBar } = Search

    return (
        <React.Fragment>
            <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
                keyField={keyField}
                columns={columns}
                data={tableData}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                        keyField={keyField}
                        columns={columns}
                        data={tableData}
                        search
                    >
                        {toolkitProps => (
                            <React.Fragment>
                                {/* SEARCH AND EXPORT */}
                                <div className='table-search-and-export'>
                                    {actionSelect && actionSelect}
                                    <SearchBar
                                        className='table-search'
                                        {...toolkitProps.searchProps}
                                    />
                                    <div 
                                        className='table-export-container'
                                        onClick={actionExport}
                                    >
                                        <img 
                                            src={IconExport} 
                                            alt=''
                                            className='table-export-button'
                                        />
                                    </div>
                                </div>

                                {/* TABLE */}
                                <BootstrapTable
                                    keyField={keyField}
                                    defaultSorted={defaultSorted}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                        'table align-middle table-nowrap'
                                    }
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                />

                                {/* PAGINATION AND SIZE PER PAGE */}
                                <div className='table-pagination-container'>
                                    {/* SIZE PER PAGE DROPDOWN */}
                                    <SizePerPageDropdownStandalone
                                        className='table-size-perpage'
                                        {...paginationProps}
                                    />

                                    {/* SIZE PER PAGE INFO */}
                                    <div className='table-size-perpage-info'>
                                        <p className='table-size-perpage-text'>
                                            Showing {startAndEndPage} of {tableData.length}
                                        </p>
                                    </div>

                                    {/* PAGINATION */}
                                    <div className='table-pagination'>
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                </div>

                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                )}
            </PaginationProvider>
        </React.Fragment>
    )
}

export default Table
