import { makeStyles } from '@material-ui/core/styles'
const cardHeight = 160

const useStyles = makeStyles((theme) => ({
  	gridContainer: {
		flex: 1,
        width: '100%',
        boxSizing: 'border-box',
        maxHeight: cardHeight,
  	},
    gridItem: {
        maxHeight: cardHeight,
        height: cardHeight,
        boxSizing: 'border-box',
        borderRadius: 12,
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 50,
        boxShadow: '0 0 10px 1px silver',
    },
    gridItemLeft: {
        marginRight: 20,
    },
    gridItemMiddle: {
        marginLeft: 10,
        marginRight: 10,
    },
    gridItemRight: {
        marginLeft: 20,
    },
    gridItemContent: {
        maxHeight: cardHeight,
    },
    gridItemTitleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    gridItemTitle: {
        fontWeight: 700,
        fontSize: 16,
        color: 'white',
    },
    gridItemCircle: {
        width: 7,
        height: 7,
        borderRadius: '50%',
        marginLeft: 7,
    },
    gridItemValue: {
        fontWeight: 700,
        fontSize: 42,
        color: 'white',
    },
}))

export default useStyles