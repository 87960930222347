import XLSX from 'xlsx'
import { format } from 'date-fns'

const convertTimeStampToDate = (inputTimeStamp) => {
    const date = new Date(inputTimeStamp)
    const formattedDate = format(date, 'dd MMM yyyy, HH:mm')
    return formattedDate
}

const exportDeviceDataToExcel = (inputData) => {
    const output = inputData.map((item, index) => {
        let newItem = {}
        newItem['ID'] = item['tracker_id']
        newItem['Name'] = item['label']
        newItem['Phone'] = item['src_phone']
        newItem['Device ID'] = item['src_device_id']
        newItem['Date Added'] = item['creation_date']
        newItem['Sales Point'] = item['group_title']
        newItem['Status'] = item['connection_status']
        return newItem
    })

    const sheetData = XLSX.utils.json_to_sheet(output)
    
    const workBook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workBook, sheetData, 'Device Data')

    XLSX.writeFile(workBook, `Wuling_Device_Data_${convertTimeStampToDate(new Date().getTime())}.xlsx`)
}

const exportStockDataToExcel = (inputData) => {
    const output = inputData.map((item, index) => {
        let newItem = {}
        newItem['No'] = index + 1
        newItem['Sales Point'] = item['sales_point']
        newItem['Stock'] = item['stock']
        newItem['Online'] = item['online']
        newItem['Min Stock'] = item['min_stock']
        return newItem
    })

    const sheetData = XLSX.utils.json_to_sheet(output)
    
    const workBook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workBook, sheetData, 'Stock Data')

    XLSX.writeFile(workBook, `Wuling_Stock_Data_${convertTimeStampToDate(new Date().getTime())}.xlsx`)
}

export { exportDeviceDataToExcel, exportStockDataToExcel }