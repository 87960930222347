import { makeStyles } from '@material-ui/core/styles'

// COLORS
const CUSTOMDARKSLATEGRAY = '#3F4254'
const CUSTOMWHITESMOKE = '#EFEFF3'

const useStyles = makeStyles((theme) => ({
  	gridContainer: {
		flex: 1,
        marginTop: 30,
  	},
    gridItem: {
        paddingRight: 30,
    },
    itemKey: {
        color: CUSTOMDARKSLATEGRAY,
        fontSize: 14,
        marginBottom: 5,
    },
    itemValue: {
        color: CUSTOMDARKSLATEGRAY,
        fontSize: 14,
        backgroundColor: CUSTOMWHITESMOKE,
        height: 44,
        paddingLeft: 12,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        fontWeight: 700,
    },
    itemValueCapitalize: {
        textTransform: 'capitalize',
    },
    divisorLine: {
        height: 1,
        width: '100%',
        backgroundColor: CUSTOMWHITESMOKE,
        margin: '20px 30px 40px 0px'
    }
}))

export default useStyles